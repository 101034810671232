export enum moResourceType {
	/*UNDEFINED*/
	MO_RESOURCETYPE_UNDEFINED = 0,
	/*INTERNAL CORE*/
	MO_RESOURCETYPE_FILE,
	MO_RESOURCETYPE_VIDEO,
	MO_RESOURCETYPE_NET,
	MO_RESOURCETYPE_FILTER,
	MO_RESOURCETYPE_TIME,
	MO_RESOURCETYPE_DATA,
	MO_RESOURCETYPE_TEXTURE,
	MO_RESOURCETYPE_SOUND,
	MO_RESOURCETYPE_MODEL,
	MO_RESOURCETYPE_FONT,
	MO_RESOURCETYPE_GUI,
	MO_RESOURCETYPE_RENDER,
	MO_RESOURCETYPE_GL,
	MO_RESOURCETYPE_FB,
	MO_RESOURCETYPE_SHADER,
	MO_RESOURCETYPE_SCRIPT,
	MO_RESOURCETYPE_MATH,
	MO_RESOURCETYPE_DEBUG,
	/*EXTRAS*/
	MO_RESOURCETYPE_TEXT,
	MO_RESOURCETYPE_MEMORY,
	MO_RESOURCETYPE_XML,
	MO_RESOURCETYPE_DB,
	MO_RESOURCETYPE_DYNAMICENGINE,
	MO_RESOURCETYPE_DECODER,

	/*RESERVED*/
	MO_RESOURCETYPE_RESERVED1,
	MO_RESOURCETYPE_RESERVED2,
	MO_RESOURCETYPE_RESERVED3,
	MO_RESOURCETYPE_RESERVED4
};
